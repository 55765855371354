$lines-to-show: 2;

body{
    background: url('../assets/images/page-bg.svg');
    position: relative;
    min-height: 100vh;

    &::before{
        content: '';
        position: absolute;
        background: rgba($color: $white, $alpha: .94);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.card{
    @include box-shadow($box-shadow);
    border: none;
}

.title{
    font-size: $font-size-base*2.125;
    font-weight: 700;
    color: $gray-800;
    line-height: normal;
}
.above-title{
    background: $primary;
    font-size: $font-size-base*.875;
    letter-spacing: 5px;
    display: inline;
    padding: $spacer*.3 $spacer*.8;
    text-transform: uppercase;
    color: $white;
}
.under-title-2{
    color: $blue;
    font-weight: 700;
}
.bg-primary-gradient{
    @include gradient-x($start-color: $primary, $end-color: lighten($primary, 20%), $start-percent: 0%, $end-percent: 100%);
    padding:.5rem  $spacer*1.3125;
    color: $white;
    font-weight: 700;
    margin-bottom: $spacer;
}
.shadow-top{
    background: $white;
    box-shadow: 0px 10px 10px 10px rgba($color: $black, $alpha: .1);
}

.bg-yellow {
    background: $yellow;
}

.bg-orange{
    background: $orange;
}
.bg-light-blue{
    background: $light-blue;
}
.btn{
    font-weight: 600;
}
.h-100p{
    max-height: 100px;
    object-fit: cover;
}
.font-weight-semibold{
    font-weight: 600;
}
.bg-secondary90{
    background: rgba($color: $secondary, $alpha: .9);
}

.cursor-hand{
    cursor: pointer;
}

.h-two-lines {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 400px;
    height: $font-size-base * $line-height-base * $lines-to-show; /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }     