@import "~bootstrap/scss/functions";
@import "../../assets/variables";
@import "~bootstrap/scss/mixins";

.message-container{

    .message-figure{
        margin: 0;
        text-align: center;
        border: 1px solid #ccc;
       
        img{
        // height: 220px;
       
        @include media-breakpoint-down(sm)
        {
            margin: auto;
            display: block;
        }
    }
}

.message-content-box{
 
    @include media-breakpoint-down(sm)
    {
        margin-top: 30px;
    }
     
    p{ 
        line-height: 24px;
    }
     
}

}