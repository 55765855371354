@import "~bootstrap/scss/functions";
@import "../../assets/variables";
@import "~bootstrap/scss/mixins";

.progress{
    height: .5rem;
}

.chrt-icon{    
    height: 15px;
}
.btn-outline-secondary .on{
    display: block;
}
.btn-outline-secondary .off{
    display: none;
}
.btn-outline-secondary:hover .off{
    display: block;
}
.btn-outline-secondary:hover .on{
    display: none;
}
.active .on{
    display: none; 
}
.active .off{
    display: block !important; 
}

.mt-7{
    margin-top: -7px;
}