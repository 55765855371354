//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/mixins";
//@import "../../assets/variables";

.aashwasanBulkUploadInput {
  margin-top: 2rem;
  width: 26rem;
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dashed #bdbdbd;
  border-radius: 8px;
  flex-direction: column;

  div {
    position: relative;
    cursor: pointer;

    svg {
      width: 100px;
      height: 100px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
}
